import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/testimonial_childrensalon.jpg',
      testimonialText:
        'I have had the pleasure of collaborating with the 3StepWeb on several projects, and I can confidently say that their impact on our team and workflow has been really positive. Working with 3StepWeb has streamlined our workflow and fostered a spirit of collaboration. Their team is responsive, communicative, and always willing to go the extra mile to meet our project goals and deadlines.',
      avatarName: 'Grant H',
      avatarDesignation: 'Design Manager - Childrensalon',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/testimonial_peer_pay.jpg',
      testimonialText:
        'I was really pleased with the professionalism and technical expertise of the 3StepWeb team. Their commitment and dedication was a key reason for the success of the project and getting us to launch on time',
      avatarName: 'Alan Burns',
      avatarDesignation: 'CTO - FloFunder',
      ratings: '5',
    },
    
    {
      testimonialThumb: '/images/testimonial_creative_developments.jpg',
      testimonialText:
        'Being far from tech-savvy, I have found navigating the digital landscape a challenge. Thankfully, 3Step Web has been a lifesaver. Their team made the entire process simple and painless, offering invaluable assistance. They\'ve truly bridged the tech gap for me.',
      avatarName: 'Jamie',
      avatarDesignation: 'CEO - Creative Developments',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/testimonial_childrensalon.jpg',
      testimonialText:
        'I\'ve worked with 3Step on several projects related to Childrensalon`s website and app, and I couldn\'t be more pleased with the results. They took the time to understand our business needs and objectives and have helped to optimise our website performance. Could not recommend them enough!',
      avatarName: 'Anya M',
      avatarDesignation: 'UI Designer - Childrensalon',
      ratings: '5',
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
