import React from 'react'
import Slider from "react-slick";
import Div from '../Div';
import Post from '../Post';
import blogs from '../../content/content.js';

export default function PostSlider() {
  const postData = blogs;
  
  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows:false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  return (
    <Slider {...settings} className='cs-gap-24 m-4'>
      {postData.map((item, index)=>(
          <Div >
            <Post 
              key = {index}
              url = {item.href}
              src={item.src} 
              date={item.date}
              title={item.title}
            />
          </Div>
        ))}
    </Slider>
  )
}
