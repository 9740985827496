import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
//import ServicePageAIAccordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsAIPage() {
  pageTitle('AI Integrations');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='AI Integrations'
        bgSrc='/images/service_hero_ai_bg.jpg'
        pageLinkText={'AI Integrations'}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='The path to success' 
          subtitle='AI Integrations' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Setting goals'
              subtitle='Identify business areas that could benefit from AI, such as data analysis or customer service. Clearly define your goals for AI implementation. Consult stakeholders like management and department heads for insights and approval.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Appropriate toolbox'
              subtitle='We will research and identify suitable AI solutions for your business requirement. We will begin with a proof of concept to test effectiveness and ROI.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Build & Maintain'
              subtitle='We will Integrate AI solutions with your existing technology infrastructure. Continuously monitor system performance and adjust as needed, using metrics and KPIs aligned with initial goals.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Some of our AI Integration services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='#' btnText='Large language Model Translations' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button link ="https://describer.3stepweb.co.uk/S" btnText='Customer Review Analysis' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='AI Chatbot' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button link="https://describer.3stepweb.co.uk/" btnText='Automated Content Generation' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='AI Imagery Generation' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
          <Spacing lg='40' md='10'/>

          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
                <img src="/images/athena.png" alt="athena" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='20' md='40'/>
          </Div>
              <Div  className="col-lg-6 offset-xl-1">
                <a href = "https://describer.3stepweb.co.uk/" >
                <h2 className="cs-font_50 cs-m0" >Athena
                </h2>
                </a>
                <Spacing lg='20' md='10'/> 
              <Div className="row">
              <Div className="col-lg-6">
                <Button link="https://describer.3stepweb.co.uk/" btnText='Our very own AI tool' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
              </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>     
      
       {/* Hiding FAQ Accordion component  

      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <ServicePageAIAccordion/>
            </Div>
          </Div>
        </Div>
      </Div>

       */}

      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title="Let’s bring your next <br /> <i>experience</i> to life" 
          btnText='Get in touch' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
