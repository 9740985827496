import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
//import ServicePageAIAccordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsCMSPage() {
  pageTitle('AI Integrations');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Content Management System Development'
        bgSrc='/images/service_hero_ai_bg.jpg'
        pageLinkText={'Content Management System Development'}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='The path to success' 
          subtitle='Content Management System Development' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Tailored CMS Expertise'
              subtitle='In-depth expertise in CMS-based web development catering to unique business needs, ensuring a tailored approach for effective online solutions'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='User-Friendly Content Management'
              subtitle="Control your website's content effortlessly on user-friendly CMS platform, enabling easy updates to text, images, and multimedia elements without technical hurdles."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Scalable, SEO-Optimised Solutions'
              subtitle='Offering scalable CMS platforms adaptable to varying needs, from blogs to e-commerce sites, coupled with SEO optimisation for enhanced visibility and higher search engine rankings.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">CMS Platforms we build on</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='#' btnText='Wordpress' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Shopify' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Magento' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>     
      
       {/* Hiding FAQ Accordion component 

      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <ServicePageAIAccordion/>
            </Div>
          </Div>
        </Div>
      </Div>

         */}

      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title="Let’s bring your next <br /> <i>experience</i> to life" 
          btnText='Get in touch' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
