import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
// import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
// import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsAIPage from './components/Pages/ServiceDetailsAIPage';
import ServiceDetailsFEDevPage from './components/Pages/ServiceDetailsFEDevPage';
import ServiceDetailsAppDevPage from './components/Pages/ServiceDetailsAppDevPage';
import ServiceDetailsCMSPage from './components/Pages/ServiceDetailsCMSPage';
import ServicesPage from './components/Pages/ServicesPage';
// import TeamPage from './components/Pages/TeamPage';
// import PortfolioPage from './components/Pages/PortfolioPage';
// import TeamDetails from './components/Pages/TeamDetails';
// import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
// import CreativePortfolioHome from './components/Pages/CreativePortfolioHome';
// import DigitalAgencyHome from './components/Pages/DigitalAgencyHome';
// import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
// import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome';
import CaseStudyShowcaseHome from './components/Pages/CaseStudyShowcaseHome';
import Layout from './components/Layout';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
// import FaqPage from './components/Pages/FaqPage';


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route
            path="photography-agency"
            element={<PhotographyAgencyHome />}
          />
          <Route path="digital-agency" element={<DigitalAgencyHome />} />
          <Route path="marketing-agency" element={<MarketingAgencyHome />} /> */}
          <Route path="about" element={<AboutPage />} />

          {/*Start Service Pages*/}

          <Route path="service" element={<ServicesPage />} />
          <Route
            path="service/front-end-development"
            element={<ServiceDetailsFEDevPage />}
          />
          <Route
            path="service/ai"
            element={<ServiceDetailsAIPage />}
          />
          <Route
            path="service/mobile-app-development"
            element={<ServiceDetailsAppDevPage />}
          />
          <Route
            path="service/cms-development"
            element={<ServiceDetailsCMSPage />}
          />

          {/*End Service Pages*/}

          {/* <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} /> */}
          <Route path="blog"  >
            <Route path= ":id" element={<BlogDetailsPage />} />
            </Route>
          <Route path="contact" element={<ContactPage />} />
          {/* <Route path="team" element={<TeamPage />} />
          <Route path="team/:teamDetails" element={<TeamDetails />} /> 
          <Route path="faq" element={<FaqPage />} /> }*/}
           <Route
            path=":id"
            element={<CaseStudyDetailsPage /> }/>
          
        </Route>
        <Route path="case-study-showcase">
          <Route
            index element={<CaseStudyShowcaseHome />}
          />
           <Route
            path=":id"
            element={<CaseStudyDetailsPage /> }/>
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          {/* <Route
            path="creative-portfolio"
            element={<CreativePortfolioHome />}
          />
          <Route
            path="showcase-portfolio"
            element={<ShowcasePortfolioHome />}
          />
           */}
           <Route
            path="case-study-showcase"
            element={<CaseStudyShowcaseHome />}
          />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
