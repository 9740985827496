import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
//import ServicePageAIAccordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsFEDevPage() {
  pageTitle('AI Integrations');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Front-end Development'
        bgSrc='/images/service_hero_ai_bg.jpg'
        pageLinkText={'Front-end Development'}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='The path to success' 
          subtitle='Front-end Development' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Cutting-Edge Expertise'
              subtitle='We employ certified React experts committed to following industry best practices. Our focus on high-quality, scalable, and optimised code ensures your project benefits from the latest advancements in front-end development'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Comprehensive End-to-End Solutions'
              subtitle="We offer comprehensive front-end services, from design to maintenance. We're proficient in integrating React-based front-ends seamlessly with any back-end technology, making us a one-stop solution for your project."
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Customer-Centric Approach'
              subtitle="We provide custom solutions tailored to your business needs. Open and transparent communication is a cornerstone of our approach, ensuring that you're consistently informed and satisfied throughout the project lifecycle."
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Some of our Front-end services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='#' btnText='Rapid Prototying for UX testing' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Website & Application Development ' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Testing & Quality Assurance' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Post Launch Support & Maintenance' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>     
      
      {/* Hiding FAQ Accordion component  
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Common Development Questions' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <ServicePageAIAccordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      
      */}

      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title="Let’s bring your next <br /> <i>experience</i> to life"
          btnText='Get in touch' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
