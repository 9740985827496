import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Spacing from '../Spacing'
import blogs from '../../content/content.js';


export default function BlogDetailsPage() {

  const {id} = useParams();

  const [contentPiece, setContentPiece] = useState();

  useEffect(() => {
    console.log(id);
    blogs.forEach(blog => {
      if (id?.toString() === blog.href){
        setContentPiece(blog);
      }
    });
  });
  pageTitle("blog post");
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    {/* Start Page Heading Section */}
      <PageHeading 
        title={contentPiece?.title}
        bgSrc='/images/blog_details_hero_bg.jpeg'
        pageLinkText={id}
      />
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Div className="row">
          <Div>

            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">{contentPiece?.date}</span>
                  <Link to="/blog" className="cs-post_avatar">Tech</Link>
                </Div>
                <h2 className="cs-post_title">{contentPiece?.title}</h2>
                <p>{contentPiece?.intro}</p>

                <p>{contentPiece?.introp2}</p>
                <p>{contentPiece?.segway}</p>
                <Div className="row">
                  <Div className="col-md-6">
                    <img src="/images/userengagement.jpg" alt="Blog Details" className="cs-radius_15 w-100 h-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                  <Div className="col-md-6">
                    <img src="/images/blog_details_img_2.jpeg" alt="Blog Details" className="cs-radius_15 w-100 h-100 " />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                </Div>
                <ol className='my-5'>
                {contentPiece?.list.map((item => 
                <>
                <li index = {item}>{item.title}</li>
                <p>{item.paragraph.map((item =>
                  <p>{item}</p>))}</p>
                </>
                ))}
                {contentPiece?.link ? <a href={contentPiece?.link}>Take a whirl at our AI Tool at https://describer.3stepweb.co.uk/</a> : null}
                </ol>
              </Div>
              {contentPiece?.heading ? <h2>{contentPiece?.heading}</h2> : null}
              {contentPiece?.paragraphs.map((item =>
                <p>{item}</p>
              ))}
              {contentPiece?.heading2 ? <h2>{contentPiece?.heading2}</h2> : null}
              {contentPiece?.paragraphs2 ? contentPiece?.paragraphs2.map((item =>
                <p>{item}</p>
              )) : null}
            </Div>
            {/* End Details Post Content */}

            {/* Start Comment Section */}
            <Spacing lg='30' md='30'/>
            <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg='5' md='5'/>
            <p className="cs-m0">Your email address will not be published. Required fields are marked *</p>
            <Spacing lg='40' md='30'/>
            <form className="row">
              <Div className="col-lg-6">
                <label>Full Name*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
                <Div data-lastpass-icon-root="true" style={{position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important'}} /></Div>
              <Div className="col-lg-6">
                <label>Email*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Website*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Write Your Comment*</label>
                <textarea cols={30} rows={7} className="cs-form_field" />
                <Div className="cs-height_25 cs-height_lg_25" />
              </Div>
              <Div className="col-lg-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />               
                </button>
              </Div>
            </form>
            {/* End Comment Section */}
            {/* SideBar 
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg='0' md='80'/>
            <Sidebar />            
          */}
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta 
          title='Lets bring your next <br /><i>experience</i> to life.' 
          btnText='Chat with us' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
      {/* End CTA Section */}
    </>
  )
}
