import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('3Step Front-end Development Agency');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="We're a front-end development agency"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="We pride ourselves in crafting visually stunning and highly functional user interfaces, setting the highest standard in front-end development."
          subtitle="Front-end Development"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <img
          src="/images/case_study_img_1.jpeg"
          alt="Case Study"
          className="w-100 cs-radius_15"
        />
        <Spacing lg="140" md="80" />
        
       {/* Hiding Two column layout */}
       
        <h2 className="cs-font_38 text-center">About Us</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0">
          With 23 years of experience, our front-end development agency specialises in building cutting-edge React websites and React Native mobile applications, seamlessly integrated with AI technologies. 
          Operating within an agile framework, we pride ourselves on delivering projects swiftly without compromising on quality. 
          Our adaptability allows us to effortlessly fit into your existing team's infrastructure, ensuring a harmonious and efficient workflow. 
          Our extensive expertise and commitment to excellence make us a trusted partner for all your front-end development needs.
        </p>
        <Spacing lg="65" md="45" />
        <Div className="row">
          <Div className="col-sm-6">
            <img
              src="/images/case_study_img_2.jpeg"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-sm-6">
            <img
              src="/images/case_study_img_3.jpeg"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>

     

        <Spacing lg="125" md="55" />
           
           
           {/* Hiding Two column layout */}
      </Div>

      

      <Div className="cs-gradient_bg_1 cs-shape_wrap_6">
        <Div className="cs-shape_2"></Div>
        <Div className="cs-shape_2"></Div>
        <Div className="container">
          <Spacing lg="145" md="80" />
          <h2 className="cs-font_38 text-center">Why we are industry leaders</h2>
          <Spacing lg="90" md="45" />
          <Div className="row align-items-center cs-column_reverse_lg">
            <Div className="col-lg-5">
              <h3 className="cs-font_30 cs-m0">Technical Excellence and Innovation</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
                <h5>Unmatched Expertise:</h5>
                Our developers are experts in front-end development, specialising in React and JavaScript. They go beyond coding to architect extraordinary user experiences.
                <br /><br />
                <h5>Cutting-Edge Tech:</h5>
                We stay abreast of the latest trends and technologies, offering innovative solutions that set new industry standards.
                <br /><br />
                <h5>Quality Assurance:</h5>
                Our rigorous quality control ensures that every line of code meets the highest industry standards, making your satisfaction our utmost priority.
              </p>
            </Div>
            <Div className="col-lg-6 offset-lg-1 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/about_innovation.png"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
          </Div>
          <Spacing lg="100" md="80" />
          <Div className="row align-items-center">
            <Div className="col-lg-6 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/about_collab.png"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <h3 className="cs-font_30 cs-m0">Seamless Collaboration and Communication</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
                <h5>Seamless Integration:</h5>
                Our developers integrate effortlessly into your team, even remotely, eliminating onboarding hassles and boosting productivity. <br /><br />

                <h5>Clear Communication:</h5>
                Effective collaboration is built on clear communication. Our developers excel in both technical proficiency and communication skills.
              </p>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          
          <Div className="row align-items-center cs-column_reverse_lg">
            <Div className="col-lg-5">
              <h3 className="cs-font_30 cs-m0">Cost-Effectiveness and Client Focus</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
                <h5>Cost-Effective Excellence:</h5>
                We offer exceptional value, delivering top-tier work without breaking the bank. <br /><br />

                <h5>Flexibility and Scalability:</h5>
                Our engagement models adapt to your needs, allowing you to scale your team as your project evolves.<br /><br />

                <h5>Client-Centric Focus:</h5>
                We're committed to building long-term partnerships and exceeding your expectations, making your success our own.
              </p>
            </Div>
            <Div className="col-lg-6 offset-lg-1 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/about_client_focus.png"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
          </Div>
          <Spacing lg="100" md="80" />
        </Div>
      </Div>
      <Spacing lg="140" md="80" />

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s bring your next<br /> <i>experience</i> to life"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
