import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
//import ServicePageAIAccordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsAppDevPage() {
  pageTitle('AI Integrations');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Mobile App Development'
        bgSrc='/images/service_hero_ai_bg.jpg'
        pageLinkText={'Mobile App Development'}
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='The path to success' 
          subtitle='Mobile App Development' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Efficient Cross-Platform Development'
              subtitle='Develop apps for both Android and iOS using a single codebase, which saves significant time and resources. This approach is enhanced by our optimised React Native solutions, ensuring the speed and responsiveness characteristic of native apps.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Quality Assurance and Scalability'
              subtitle='Our development process includes rigorous testing regimes such as unit tests, integration tests, and end-to-end tests, ensuring a bug-free user experience. The applications are built with robust scalability in mind, making them suitable for a range of projects from startups to enterprise solutions.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Comprehensive Services and Support'
              subtitle='We offer a complete package for mobile app needs, starting from ideation to deployment and maintenance. Faster development cycles enable quicker market entry, getting the app in front of users sooner. Our post-launch support includes ongoing maintenance and updates, ensuring your app stays ahead of the curve.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Some of our Mobile App Services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='#' btnText='iOS & Android Apps' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Proof of Concept & Prototypes' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='App & Play store deployment' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='Quality Assurance & User Acceptance Testing' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='#' btnText='App maintenance' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>     
      
       {/* Hiding FAQ Accordion component

      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />

         
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <ServicePageAIAccordion/>
            </Div>
          </Div>
        </Div>
      </Div>

      */}

      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title="Let’s bring your next <br /> <i>experience</i> to life" 
          btnText='Get in touch' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
