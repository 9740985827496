import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList() {
  const partnerLogos = [
    {
      src: '/images/tech_react.svg', 
      alt:'React'
    },
    {
      src: '/images/tech_react_native.svg', 
      alt:'React Native'
    },
    {
      src: '/images/tech_aws.svg', 
      alt:'Amazon Web Services'
    },
    {
      src: '/images/tech_angular.svg', 
      alt:'Angular JS'
    },
    {
      src: '/images/tech_docker.svg', 
      alt:'Partner'
    }
  ]
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} /></div>)}
    </Div>
  )
}
