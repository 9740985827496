const caseStudies = [{
    "title" : "Rapid Prototyping Project",
    "introduction" : "Children Salon, a leading high-end retailer, embarked on a mission to enhance its mobile user experience ",
    "objective" : "The main objective of our rapid prototyping initiative was to facilitate quick iteration and validation of new design concepts for our mobile application. We aimed to involve end-users at an early stage to collect vital feedback. This approach was designed to inform our development process and help avoid potential issues later.",
    "solution" : `Iterative Prototyping : We quickly developed prototypes to test a variety of design solutions. We set up ongoing feedback loops with end-users, integrating their suggestions into each new iteration.  User Testing : We conducted user testing on different versions of the prototype to validate design choices and identify possible usability challenges.The insights gained from user testing were used to further refine the prototypes.`,
    "conclusion" : "The rapid prototyping method employed at Children Salon was instrumental in achieving a balance between speed, innovation, and user satisfaction. This successful strategy sets a new benchmark for future product development projects, underscoring the value of iterative prototyping and early engagement with users.",
    "href" : "prototyping",
    "mockup": "<Prototype />"
     
}, {
    "title" : "Design System Manager",
    "introduction" : "Children Salon, a leading high-end retailer, embarked on a mission to improve onboarding and ensuring a consistent design philosophy across designers, developers and stakeholders",
    "objective" : "The central aim of this initiative is to institute and sustain a resilient design system, thereby fostering heightened collaboration, ensuring steadfast consistency, and expediting the design process across the entirety of our diverse product teams.",
    "solution" : "Our response to this imperative involved the creation of a highly interactive design system. This system encompasses all components utilized across an expansive retail platform, extending beyond the basic function of a repository. It provides detailed, clear guidelines on the use of each component, promoting clarity and ease of understanding for the design teams.",
    "conclusion" : "The implementation of the Design System Manager emerged as a pivotal tool, not just making onboarding smoother but also aligning designers, developers, and stakeholders under a common design ethos. This tool has been crucial in fostering a more cohesive and efficient work environment, directly impacting our team's productivity and collaboration.",
    "href" : "designSystem"
}, {
    "title" : "Ai Translation tool",
    "introduction" : "Children Salon, a leading high-end retailer, embarked on a mission to improve onboarding and ensuring a consistent design philosophy across designers, developers and stakeholders",
    "objective" : "At the heart of our latest project lies a singular focus - the creation of a cutting-edge translation tool designed to seamlessly convert any text into the language of your choice.",
    "solution" : "Our approach involved leveraging the advanced capabilities of the ChatGPT4 model. Our dedicated team worked to develop a user-friendly interface that aligns seamlessly with the unique design philosophy of your business. This interface serves not just as a portal but as a customized solution, adeptly translating text into a variety of languages with ease.",
    "conclusion" : "The fruition of our AI Translation tool represents more than just a powerful asset; it's a gateway to reaching untapped niche demographics. By integrating this tool into their arsenal, the marketers gain the ability to create personalized and culturally resonant marketing materials, ensuring a deeper connection with their target audience. This tool exemplifies the transformative power of AI technologies in enhancing global communication strategies, inviting you to experience its far-reaching benefits in today's interconnected world.",
    "href" : "designSystem"
}
]



export default caseStudies;